<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modalSuportBrowser"
      tabindex="-1"
      aria-labelledby="modalSuportBrowserLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="d-flex justify-content-between align-items-center py-2 px-3">
            <p class="fs-20 mb-0" id="modalSuportBrowserLabel">
              Thông báo
            </p>
            <button
              type="button"
              class="btn-close text-black"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p class="fs-18 fw-500">
            HODO không hỗ trợ tính năng này trên trình duyệt hiện tại
            </p>
            <p class="fw-14 mb-2">
            Chúng tôi khuyến nghị bạn sử dụng trình duyệt:
            </p>
            <p class="fw-14 mb-2">
              - <span class="fw-500">PC/Laptop (Window,Mac Os,...):</span> Chrome, Microsoft Edge, Firefox
            </p>
            <p class="fw-14 mb-2">
              - <span class="fw-500">Android:</span> Chrome
            </p>
            <p class="fw-14 mb-2">
              - <span class="fw-500">iOS/iPadOs:</span> Safari
            </p>
            <p class="fs-10 text-danger mb-1">
              (Các thiết bị không xuất hiện trong danh sách vui lòng sử dụng trình duyệt Chrome)
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportBrowser'
}
</script>

<style scoped>
</style>
