<template>
  <div
    :id="participant.id"
    class="mediaWraper rounded-3 border border-2"
    :class="isDominantSpeaker ? 'border-primary' : 'border-secondary'"
    v-if="participant"
    @click="zoomTrack()"
    :ref="`participant-${participant.id}`"
  >
    <p class="user-name">{{ getNameMember(participant.participant.identity) }}</p>

    <div class="network-quality">
      <div class="network-quality--inner">
        <div
          v-for="(level) in BARS_ARRAY"
          :key="level"
          v-bind:style="{
            height: `${STEP * (level + 1)}px`,
            background: networkQualityLevelVal > level ? 'green' : 'rgba(255, 255, 255, 0.2)'
          }"
        />
      </div>
    </div>

    <div class="btn-fullscreen text-white" role="button" @click.stop>
      <div
        class="d-flex align-items-center justify-content-center"
        @click="handleToggleFullscreen(`participant-${participant.id}`)"
      >
        <svg
          v-if="!isFullscreen"
          version="1.1"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
            <g fill="currentColor" id="Core" transform="translate(-215.000000, -257.000000)">
              <g id="fullscreen" transform="translate(215.000000, 257.000000)">
                <path
                  d="M2,9 L0,9 L0,14 L5,14 L5,12 L2,12 L2,9 L2,9 Z M0,5 L2,5 L2,2 L5,2 L5,0 L0,0 L0,5 L0,5 Z M12,12 L9,12 L9,14 L14,14 L14,9 L12,9 L12,12 L12,12 Z M9,0 L9,2 L12,2 L12,5 L14,5 L14,0 L9,0 L9,0 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </svg>
        <svg
          v-else
          version="1.1"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
            <g fill="currentColor" id="Core" transform="translate(-257.000000, -257.000000)">
              <g id="fullscreen-exit" transform="translate(257.000000, 257.000000)">
                <path
                  d="M0,11 L3,11 L3,14 L5,14 L5,9 L0,9 L0,11 L0,11 Z M3,3 L0,3 L0,5 L5,5 L5,0 L3,0 L3,3 L3,3 Z M9,14 L11,14 L11,11 L14,11 L14,9 L9,9 L9,14 L9,14 Z M11,3 L11,0 L9,0 L9,5 L14,5 L14,3 L11,3 L11,3 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>

    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="meet-mic-status"
      v-show="isEnabledAudio && !isEnabledScreen"
    >
      <path
        d="M12.7466 6.31135C12.4866 6.31135 12.28 6.51802 12.28 6.77802V7.83135C12.28 10.1914 10.36 12.1114 7.99995 12.1114C5.63995 12.1114 3.71995 10.1914 3.71995 7.83135V6.77135C3.71995 6.51135 3.51329 6.30469 3.25329 6.30469C2.99329 6.30469 2.78662 6.51135 2.78662 6.77135V7.82469C2.78662 10.538 4.87329 12.7714 7.53329 13.0114V14.4314C7.53329 14.6914 7.73995 14.898 7.99995 14.898C8.25995 14.898 8.46662 14.6914 8.46662 14.4314V13.0114C11.12 12.778 13.2133 10.538 13.2133 7.82469V6.77135C13.2066 6.51802 13 6.31135 12.7466 6.31135Z"
        fill="#696F7F"
      />
      <path
        d="M8.00001 1.56445C6.37334 1.56445 5.05334 2.88445 5.05334 4.51112V7.92445C5.05334 9.55112 6.37334 10.8711 8.00001 10.8711C9.62668 10.8711 10.9467 9.55112 10.9467 7.92445V4.51112C10.9467 2.88445 9.62668 1.56445 8.00001 1.56445ZM8.87335 6.19779C8.82668 6.37112 8.67335 6.48445 8.50001 6.48445C8.46668 6.48445 8.43335 6.47779 8.40001 6.47112C8.14001 6.39779 7.86668 6.39779 7.60668 6.47112C7.39334 6.53112 7.18668 6.40445 7.13334 6.19779C7.07334 5.99112 7.20001 5.77779 7.40668 5.72445C7.80001 5.61779 8.21335 5.61779 8.60668 5.72445C8.80668 5.77779 8.92668 5.99112 8.87335 6.19779ZM9.22668 4.90445C9.16668 5.06445 9.02001 5.15779 8.86001 5.15779C8.81335 5.15779 8.77335 5.15112 8.72668 5.13779C8.26001 4.96445 7.74001 4.96445 7.27334 5.13779C7.07334 5.21112 6.84668 5.10445 6.77334 4.90445C6.70001 4.70445 6.80668 4.47779 7.00668 4.41112C7.64668 4.17779 8.35334 4.17779 8.99335 4.41112C9.19335 4.48445 9.30001 4.70445 9.22668 4.90445Z"
        fill="#696F7F"
      />
    </svg>

    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="meet-mic-status"
      v-show="!isEnabledAudio && !isEnabledScreen"
    >
      <path
        d="M10.9467 4.74363V5.51697L6.09335 10.3703C5.45335 9.7903 5.05334 8.93697 5.05334 8.02363V4.74363C5.05334 3.3703 5.98668 2.2303 7.25335 1.90363C7.38001 1.8703 7.50001 1.97697 7.50001 2.10363V3.1303C7.50001 3.40363 7.72668 3.6303 8.00001 3.6303C8.27334 3.6303 8.50001 3.40363 8.50001 3.1303V2.10363C8.50001 1.97697 8.62001 1.8703 8.74668 1.90363C10.0133 2.2303 10.9467 3.3703 10.9467 4.74363Z"
        fill="#696F7F"
      />
      <path
        d="M13.2067 7.00395V8.06395C13.2067 10.7773 11.12 13.0106 8.46671 13.2439V14.6639C8.46671 14.9239 8.26005 15.1306 8.00005 15.1306C7.74005 15.1306 7.53338 14.9239 7.53338 14.6639V13.2439C6.80671 13.1773 6.12005 12.9639 5.50671 12.6239L6.19338 11.9373C6.74005 12.1906 7.35338 12.3373 8.00005 12.3373C10.36 12.3373 12.28 10.4173 12.28 8.06395V7.00395C12.28 6.75061 12.4867 6.54395 12.7467 6.54395C13 6.54395 13.2067 6.75061 13.2067 7.00395Z"
        fill="#696F7F"
      />
      <path
        d="M10.9467 7.18359V8.15026C10.9467 9.87026 9.4667 11.2503 7.7067 11.0836C7.52003 11.0636 7.33337 11.0303 7.16003 10.9703L10.9467 7.18359Z"
        fill="#696F7F"
      />
      <path
        d="M14.5133 1.95078C14.3133 1.75078 13.9866 1.75078 13.7866 1.95078L4.81996 10.9174C4.13329 10.1641 3.71996 9.16411 3.71996 8.06411V7.00412C3.71996 6.75078 3.51329 6.54411 3.25329 6.54411C2.99996 6.54411 2.79329 6.75078 2.79329 7.00412V8.06411C2.79329 9.41745 3.31329 10.6508 4.15996 11.5774L1.47996 14.2574C1.27996 14.4574 1.27996 14.7841 1.47996 14.9841C1.58662 15.0774 1.71329 15.1308 1.84662 15.1308C1.97996 15.1308 2.10662 15.0774 2.20662 14.9774L14.5133 2.67078C14.72 2.47078 14.72 2.15078 14.5133 1.95078Z"
        fill="#696F7F"
      />
    </svg>

    <div
      class="mediadiv ratio ratio-16x9 position-relative h-100"
      v-if="isEnabledVideo || isEnabledScreen"
    >
      <span class="track-loading text-white">{{ $t('lbl_loading') }}...</span>
      <VideoTrack
        :track="videoTrack"
        :muted="true"
        :isLocal="isLocalParticipant && !isEnabledScreen"
      />
    </div>
    <div v-else class="ratio ratio-16x9 h-100">
      <div class="d-flex justify-content-center align-items-center h-100">
        <svg
          width="70"
          height="71"
          viewBox="0 0 70 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M46.9583 26.5923C46.9792 26.7381 46.9792 26.9048 46.9583 27.0506C46.9583 26.9048 46.9375 26.759 46.9375 26.6131L46.9583 26.5923Z"
            fill="#484C56"
          />
          <path
            d="M45.9998 24.4881L17.979 52.509C15.0623 50.6548 13.9165 47.3423 13.9165 44.1548V27.4881C13.9165 20.3631 16.6873 17.5923 23.8123 17.5923H36.3123C42.3332 17.5923 45.229 19.5715 45.9998 24.4881Z"
            fill="#484C56"
          />
          <path
            d="M54.5832 15.4673C53.9582 14.8423 52.9373 14.8423 52.3123 15.4673L13.854 53.9256C13.229 54.5506 13.229 55.5715 13.854 56.1965C14.1665 56.4881 14.5832 56.6548 14.979 56.6548C15.3957 56.6548 15.7915 56.4881 16.104 56.1756L54.5832 17.7173C55.2082 17.0923 55.2082 16.0923 54.5832 15.4673Z"
            fill="#484C56"
          />
          <path
            d="M56.625 28.2798V43.3631C56.625 46.3423 55.1667 47.5298 54.3333 47.9673C53.9375 48.1756 53.3125 48.4048 52.5417 48.4048C51.6458 48.4048 50.5417 48.1131 49.25 47.1965L46.1667 45.0298C46.0208 49.634 44.5625 52.259 41.25 53.3631C39.9167 53.8423 38.2708 54.0506 36.2917 54.0506H23.7917C23.2917 54.0506 22.8125 54.0298 22.3125 53.9673L41.25 35.0506L53.0208 23.2798C53.5625 23.3215 54 23.4881 54.3333 23.6756C55.1667 24.1131 56.625 25.3006 56.625 28.2798Z"
            fill="#484C56"
          />
        </svg>
      </div>
    </div>
    <AudioTrack :track="audioTrack" v-if="isEnabledAudio" />
  </div>
</template>

<script>
import VideoTrack from '../VideoCore/Tracks/VideoTrack.vue'
import AudioTrack from '../VideoCore/Tracks/AudioTrack.vue'

export default {
  name: 'Participant',
  props: [
    'localParticipant',
    'participant',
    'dominantSpeaker',
    'membersMeetRoom',
    'actionCount'
  ],
  components: { VideoTrack, AudioTrack },
  data () {
    return {
      videoTrack: null,
      audioTrack: null,
      mediaStreamTrack: null,
      BARS_ARRAY: [0, 1, 2, 3, 4],
      STEP: 3,
      isFullscreen: false,
      networkQualityLevelVal:
        this.participant?.participant?.networkQualityLevel || 0,
      isAction: false,
      debounce: null,
      isEnabledAudio: false
    }
  },
  mounted () {
    const self = this
    const participant = self.participant?.participant
    participant.on('networkQualityLevelChanged', self.printNetworkQualityStats)
    self.setVideoAndAudioTrack(self.participant)
  },
  watch: {
    participant: {
      handler (data) {
        this.setVideoAndAudioTrack(data)
      },
      deep: true
    },
    actionCount: {
      handler () {
        this.setVideoAndAudioTrack()
      }
    }
  },
  computed: {
    isLocalParticipant () {
      return this.participant?.id === this.localParticipant?.sid
    },
    isDominantSpeaker () {
      return this.participant?.id === this.dominantSpeaker?.sid
    },
    isEnabledVideo () {
      return this.videoTrack?.isEnabled || false
    },
    isEnabledScreen () {
      return this.participant?.type === 'screen'
    }
  },
  methods: {
    setVideoAndAudioTrack (data) {
      const tracksData = data?.tracks || this.participant?.tracks || []
      if (!tracksData) return
      this.videoTrack = tracksData.find(track => track.kind === 'video')
      this.audioTrack = tracksData.find(track => track.kind === 'audio')
      this.isEnabledAudio = this.audioTrack?.isEnabled || false
    },
    getNameMember (id) {
      if (!this.membersMeetRoom) return id
      const found = this.membersMeetRoom.find(
        m => m.user_id === parseInt(id) || m.doctor?.user_id === parseInt(id)
      )
      let name =
        found && found?.user?.name
          ? found?.user?.name
          : found?.user?.email
            ? found?.user?.email
            : id
      if (found?.doctor) {
        name = found?.doctor?.name
      }
      if (this.isEnabledScreen) {
        return name + ' đang trình bày'
      } else {
        return name
      }
    },
    isScreen (name) {
      return name?.includes('screen')
    },
    zoomTrack () {
      this.isAction = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.isAction = false
        this.$emit('zoomTrack', this.participant)
      }, 300)
    },
    handleToggleFullscreen (refElement) {
      const fullScreenElement = this.$refs[refElement]
      if (document.fullscreenElement) {
        // exitFullscreen is only available on the Document object.
        document.exitFullscreen()
        this.isFullscreen = false
      } else {
        fullScreenElement.requestFullscreen().catch(err => {
          console.log(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
          )
        })
        this.isFullscreen = true
      }
    },
    printNetworkQualityStats (networkQualityLevel, networkQualityStats) {
      // Print in console the networkQualityLevel using bars
      // console.log(
      //   {
      //     1: '▃',
      //     2: '▃▄',
      //     3: '▃▄▅',
      //     4: '▃▄▅▆',
      //     5: '▃▄▅▆▇'
      //   }[networkQualityLevel] || ''
      // )
      this.networkQualityLevelVal = networkQualityLevel
    }
  },
  beforeDestroy () {
    const participant = this.participant?.participant
    participant.off(
      'networkQualityLevelChanged',
      this.printNetworkQualityStats
    )
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .empty-stream {
    background-color: #121519;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  video::-webkit-media-controls-play-button {
    display: none;
  }
  video::-webkit-media-controls-timeline {
    display: none;
  }
  video::-webkit-media-controls-current-time-display {
    display: none;
  }
  video::-webkit-media-controls-time-remaining-display {
    display: none;
  }
  video::-webkit-media-controls-mute-button {
    display: none;
  }
  video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
  }
  video::-webkit-media-controls-volume-slider {
    display: none;
  }
  .network-quality {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 2em;
    height: 2em;
    padding: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;

    &--inner {
      display: flex;
      align-items: flex-end;

      div {
        width: 2px;
        margin-right: 1px;

        &:not(:last-child) {
          border-right: none;
        }
      }
    }
  }

  .btn-fullscreen {
    position: absolute;
    bottom: 3%;
    right: 2%;
    z-index: 9;
    width: 1.2rem;
    height: 1.2rem;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
      transform-origin: center;
    }
  }
}
</style>
