<template>
  <div class="relative">
    <el-button
      :disabled="!canSign"
      @click="isOpen = !isOpen"
      class="fs-16"
      style="height: 100%"
      size="small"
      plain
      slot="reference"
      type="primary"
      >{{ $t("Chữ ký") }}</el-button
    >

    <div v-if="isOpen" class="cs-popover">
      <div class="cs-container">
        <div v-loading="isLoading" class="cs-grid">
          <div class="cs-create cursor-pointer" @click.stop="openModalCreate">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 1024 1024"
            >
              <path
                fill="#20409B"
                d="M512 0C229.232 0 0 229.232 0 512c0 282.784 229.232 512 512 512c282.784 0 512-229.216 512-512C1024 229.232 794.784 0 512 0zm0 961.008c-247.024 0-448-201.984-448-449.01c0-247.024 200.976-448 448-448s448 200.977 448 448s-200.976 449.01-448 449.01zM736 480H544V288c0-17.664-14.336-32-32-32s-32 14.336-32 32v192H288c-17.664 0-32 14.336-32 32s14.336 32 32 32h192v192c0 17.664 14.336 32 32 32s32-14.336 32-32V544h192c17.664 0 32-14.336 32-32s-14.336-32-32-32z"
              />
            </svg>
          </div>
          <div
            v-for="(signature, index) in signatures"
            :key="index"
            class="cs-image-container cursor-pointer"
            :class="checkActive(signature) ? 'cs-active' : ''"
            @click.stop="(e) => handleSelectSignature(e, signature)"
          >
            <img
              class="cs-image"
              v-if="signature.signature_base64"
              :src="generateImageSrcBase64(signature.signature_base64)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignatureRequest from '../../api/request/SignatureRequest'
import ModalCreateSignature from './ModalCreateSignature.vue'

export default {
  name: 'SelectSignature',
  components: { ModalCreateSignature },
  props: {
    signerID: Number,
    refreshSignature: Number
  },
  data () {
    return {
      signatures: [],
      isLoading: false,
      selectedSignature: {},
      isOpen: false,
      envelopeInfo: {}
    }
  },
  watch: {
    signerID () {
      this.getDoctorSignatures()
    },
    refreshSignature () {
      this.selectedSignature = {}
      this.getDoctorSignatures()
      this.isOpen = false
    }
  },
  created () {
    this.getDoctorSignatures()
  },
  computed: {
    canSign () {
      return this.signerID === this.$user?.id
    }
  },
  methods: {
    async getDoctorSignatures () {
      if (!this.signerID) return

      try {
        this.isLoading = true
        const request = new SignatureRequest()

        const params = {
          user_id: this.signerID
        }
        const response = await request.getDoctorSignatures(params)
        this.signatures = response.data || []
        this.selectedSignature = this.signatures?.[0] || {}
        this.$emit('onChange', this.selectedSignature)
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    generateImageSrcBase64 (content) {
      var prefix = 'data:image/png;base64,'

      return prefix + content
    },
    handleSelectSignature (e, item) {
      e.stopPropagation()

      this.selectedSignature = item
      this.isOpen = false
      this.$emit('onChange', item)
    },
    checkActive (item) {
      return item.id === this.selectedSignature?.id
    },
    openModalCreate () {
      this.$emit('onOpenModalCreate')
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.cs-image-container {
  cursor: pointer;
  padding: 8px;
  border: 1px dashed #000;
  width: 200px;
  height: 100px;
}

.cs-popover {
  position: absolute;
  top: 50px;
  right: 0px;
  width: 480px;
  z-index: 1000000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  padding: 16px;
  overflow-y: auto;
  height: 100vh;
}

.cs-active {
  border: 2px dashed #0ff76c;
}

.cs-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
}

.cs-container {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.cs-create {
  cursor: pointer;
  padding: 8px;
  border: 1px solid #000;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>