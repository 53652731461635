import { render, staticRenderFns } from "./HoDoConference.vue?vue&type=template&id=0aa37651&scoped=true"
import script from "./HoDoConference.vue?vue&type=script&lang=js"
export * from "./HoDoConference.vue?vue&type=script&lang=js"
import style0 from "./HoDoConference.vue?vue&type=style&index=0&id=0aa37651&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aa37651",
  null
  
)

export default component.exports