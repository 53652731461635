<template>
  <div>
    <el-dialog
      :visible.sync="isOpen"
      fullscreen
      :before-close="handleClose"
      :show-close="false"
    >
      <div v-loading="isLoading || isRendering">
        <div
          class="cs-sign flex justify-between items-center py-2 px-4 gap-4 mb-4"
        >
          <div class="fs-20 font-bold uppercase text-white">
            {{ getEnvelopeNameByType(documentType) }}
          </div>

          <div class="flex gap-2">
            <el-button
              class="fs-16"
              size="small"
              plain
              type="primary"
              @click="handleDownload"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 304 384"
              >
                <path
                  fill="currentColor"
                  d="M299 128L149 277L0 128h85V0h128v128h86zM0 320h299v43H0v-43z"
                />
              </svg>
              {{ $t("Tải xuống") }}</el-button
            >
            <el-button
              class="fs-16"
              size="small"
              plain
              type="primary"
              @click="handlePrint"
            >
              <svg
                data-v-9de419a6=""
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  data-v-9de419a6=""
                  d="M14.8332 4.66667H13.9998V0.5H3.99984V4.66667H3.1665C1.78317 4.66667 0.666504 5.78333 0.666504 7.16667V12.1667H3.99984V15.5H13.9998V12.1667H17.3332V7.16667C17.3332 5.78333 16.2165 4.66667 14.8332 4.66667ZM5.6665 2.16667H12.3332V4.66667H5.6665V2.16667ZM12.3332 13.8333H5.6665V10.5H12.3332V13.8333ZM13.9998 10.5V8.83333H3.99984V10.5H2.33317V7.16667C2.33317 6.70833 2.70817 6.33333 3.1665 6.33333H14.8332C15.2915 6.33333 15.6665 6.70833 15.6665 7.16667V10.5H13.9998Z"
                  fill="currentColor"
                ></path>
                <path
                  data-v-9de419a6=""
                  d="M13.9998 8.41667C14.4601 8.41667 14.8332 8.04357 14.8332 7.58333C14.8332 7.1231 14.4601 6.75 13.9998 6.75C13.5396 6.75 13.1665 7.1231 13.1665 7.58333C13.1665 8.04357 13.5396 8.41667 13.9998 8.41667Z"
                  fill="currentColor"
                ></path>
              </svg>
              {{ $t("In") }}</el-button
            >
            <el-button
              class="fs-16"
              size="small"
              plain
              type="primary"
              @click="handleClose"
              >{{ $t("Đóng") }}</el-button
            >
          </div>
        </div>
        <div class="text-black">
          <div class="p-2 cursor-none cs-container">
            <div :id="containerID" class="pdf-container cs-cursor">
              <vue-pdf-embed
                :ref="elementID"
                :id="elementID"
                :source="urlPDF"
                @internal-link-clicked="handleClickedPage"
                @loaded="handleDocumentLoad"
                @rendered="handleDocumentRender"
              />
            </div>
          </div>
        </div>
        <div
          v-show="showPopup && signatureFocus"
          :style="popupStyle"
          class="popup flex flex-col gap-2"
        >
          <div class="font-bold">{{ signatureFocus.signatory_name }}</div>
          <div>{{ signatureFocus.signatory_email }}</div>
          <div :set="(imageSrc = handleGetImageBase64(signatureFocus))">
            <img
              v-if="imageSrc"
              style="width: 80px; height: 40px"
              :src="imageSrc"
            />
          </div>
          <div :set="(signAt = getSignAt(signatureFocus))">
            <div v-if="signAt">
              <strong class="mr-1">Đã ký vào:</strong>
              <i>{{ signAt }}</i>
            </div>
            <div v-else>
              <span class="mr-1">Trạng thái:</span>
              <i class="font-bold">Chưa ký</i>
              <!-- <i>{{ signAt }}</i> -->
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
      
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import SignatureRequest from '@/api/request/SignatureRequest'
import { ENVELOPE_DOCUMENT_TYPE } from '../../utils/constants'
import appUtils from '../../utils/appUtils'
import avatar from '../../assets/images/avatar.png'
import uploadS3File from '../../utils/uploadS3File'

export default {
  name: 'PDFViewer',
  components: { VuePdfEmbed },
  props: {
    elementIDProps: String,
    containerIDProps: String,
    imageIDProps: String
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      pageCount: null,
      signatureImage: '',
      isRendering: true,
      canvasSelectedClicked: false,
      imageX: 80,
      imageY: 40,
      urlPDF: '',
      documentPDFViewer: null,
      metadata: null,
      documentType: null,
      documentID: null,
      envelopeInfo: {},
      showPopup: false,
      popupStyle: {},
      signatureFocus: {},
      elementID: 'PDFViewer',
      containerID: 'PDFViewContainer',
      imageID: 'PDFViewerImageID',
      signed_at: null,
      isDocBase64: false,
      signatureData: []
    }
  },
  computed: {
    request () {
      return new SignatureRequest()
    },
    signatories () {
      const envelope_signatories =
        this.envelopeInfo?.envelope_signatories || []
      const formatSignatories = envelope_signatories?.map((item) => {
        const signatureDataItem =
          this.signatureData?.find((signature) => {
            console.log({ signature, item })
            return (
              Number(signature?.signature_index) ===
              Number(item?.signatory_metadata?.signed_index)
            )
          }) || {}

        console.log(signatureDataItem)

        return {
          ...item,
          ...signatureDataItem
        }
      })

      return (
        formatSignatories?.sort(
          (a, b) => a?.signatory_order - b?.signatory_order
        ) || []
      )
    }
  },
  watch: {
    elementIDProps () {
      this.elementID = this.elementIDProps || 'PDFViewer'
    },
    containerIDProps () {
      this.containerID = this.containerIDProps || 'PDFViewContainer'
    },
    imageIDProps () {
      this.imageID = this.imageIDProps || 'PDFViewerImageID'
    }
  },

  created () {
    this.elementID = this.elementIDProps || 'PDFViewer'

    this.containerID = this.containerIDProps || 'PDFViewContainer'

    this.imageID = this.imageIDProps || 'PDFViewerImageID'
  },
  mounted () {},
  methods: {
    handleClose () {
      // const container = document.getElementById('PDFViewContainer')

      // container.removeEventListener('mousemove', (e) => {})

      // const canvases = container?.querySelectorAll('canvas')
      // canvases.forEach((item, index) => {
      //   item.removeEventListener('mousedown', function (event) {})
      // })

      this.urlPDF = ''
      this.documentPDFViewer = null
      this.metadata = null
      this.documentID = null
      this.documentType = null
      this.canvasSelectedClicked = false
      this.isOpen = false
      this.showPopup = false
      this.popupStyle = {}
      this.signatureFocus = {}
      // this.isRendering = true
    },
    async handleOpen (url, docType, documentID, isDocBase64Prop) {
      this.isOpen = true
      if (isDocBase64Prop) {
        const docBase64 = this.generatePDFSrcBase64(url)
        this.urlPDF = docBase64
        this.isDocBase64 = isDocBase64Prop
      } else {
        if (url.includes('https://')) {
          this.urlPDF = url
        } else {
          const s3Url = await uploadS3File.getLinkVideoAWS(url)
          this.urlPDF = s3Url
        }
      }
      this.documentType = docType
      this.documentID = documentID

      const responseDocument = await this.handleProcessDocument()
      await this.getEnvelopeDetailByDocumentID(responseDocument)

      this.$nextTick(() => {
        this.handleCheckSignatories()
      })
      // this.handleCheckMouseInPage()
    },
    handleDocumentLoad (documentPDF) {
      this.pageCount = documentPDF.numPages
      this.documentPDFViewer = documentPDF
    },
    handleDocumentRender () {
      // if (!this.isRendering) return
      // // this.handleCheckMouseInPage()

      this.isRendering = false
      this.handleCheckMouseInPage()
    },
    handleClickedPage (page) {
      console.log('clicked', page)
    },

    generateImageSrcBase64 (content) {
      const prefix = 'data:image/png;base64,'

      return prefix + content
    },
    getEnvelopeNameByType (type) {
      switch (type) {
        case ENVELOPE_DOCUMENT_TYPE.EXAMINATION:
          return 'Phiếu khám bệnh'
        case ENVELOPE_DOCUMENT_TYPE.PRESCRIPTION:
          return 'Đơn thuốc'
        case ENVELOPE_DOCUMENT_TYPE.INDICATION_RESULT:
          return 'Kết quả chỉ định'
        case ENVELOPE_DOCUMENT_TYPE.SURGERY_FORM:
          return 'Phiếu phẫu thuật/thủ thuật'
        case ENVELOPE_DOCUMENT_TYPE.TREATMENT_FORM:
          return 'Phiếu điều trị'
        case ENVELOPE_DOCUMENT_TYPE.CARE_FORM:
          return 'Phiếu chăm sóc'
        case ENVELOPE_DOCUMENT_TYPE.NUTRITIONAL_FORM:
          return 'Phiếu đánh giá dinh dưỡng'
        case ENVELOPE_DOCUMENT_TYPE.CONFIRM_TREATMENT:
          return 'Phiếu xác nhận điều trị'
        case ENVELOPE_DOCUMENT_TYPE.SUMMARY_TREATMENT_FORM:
          return 'Phiếu sơ kết điều trị'
        default:
          return ''
      }
    },
    async getEnvelopeDetailByDocumentID () {
      try {
        this.isLoading = true

        const params = {
          doc_id: this.documentID,
          doc_type: this.documentType
        }
        const response = await this.request.getEnvelopeDetailByDocumentID(
          params
        )
        this.envelopeInfo = response.data || {}
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleCheckMouseInPage () {
      const container = document.getElementById(this.containerID)
      const canvases = container?.querySelectorAll('canvas')
      const self = this
      canvases.forEach((item, index) => {
        item.addEventListener('mousedown', function (event) {
          self.handleCalcPositionSignature(item, event, index + 1, true)
          self.canvasSelectedClicked = !self.canvasSelectedClicked
        })
      })

      canvases.forEach((item, index) => {
        item.addEventListener('mousemove', function (event) {
          self.handleCalcPositionSignature(item, event, index + 1, false)
        })
      })
    },
    async handleCalcPositionSignature (canvas, event, page, isClick) {
      if (isClick && this.showPopup) {
        this.showPopup = false
        return
      }

      const pageViewer = await this.getPDFPageViewer(page)

      // Lấy kích thước của canvas
      const rect = canvas.getBoundingClientRect()

      // Tính toán tọa độ x, y của chuột trên canvas
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      // Tính toán tọa độ trên PDF từ tọa độ chuột trên canvas
      const viewport = pageViewer.getViewport({ scale: 1.0 })
      const pdfX = (x / rect.width) * viewport.width
      const pdfY = (y / rect.height) * viewport.height
      const pointY = parseInt(viewport.height - pdfY)
      const pointX = pdfX

      this.signatories.forEach((signatory, index) => {
        const [signLLx, signLLy, signURx, signURy] =
          signatory?.signatory_metadata?.signature_position_rectangle_pdf
            ?.split(',')
            ?.map((item) => parseInt(item))

        // Check if point focus in signLLx, signLLy, signURx, signURy
        if (
          pointX >= signLLx &&
          pointX <= signURx &&
          pointY >= signLLy &&
          pointY <= signURy &&
          Number(page) ===
            Number(signatory?.signatory_metadata?.signature_position_page)
        ) {
          if (isClick) {
            this.showPopup = true
            const canvasHeight = rect.height
            const pageHeight = (page - 1) * canvasHeight
            this.popupStyle = {
              top: `${y + pageHeight - 100}px`,
              left: `${event.clientX - 100}px`
            }
            this.signatureFocus = signatory
          } else {
            canvas.style.cursor = 'pointer' // Add this line to set the cursor to pointer
          }
        } else {
          // this.showPopup = false
          canvas.style.cursor = 'default' // Add this line to set the cursor to pointer
        }
      })
    },
    async getPDFPageViewer (pageNum) {
      const pageViewer = await this.documentPDFViewer.getPage(pageNum)
      return pageViewer
    },
    getSignAt (data) {
      console.log(data)
      return (
        data.signed_at ||
        (data.signatory_metadata?.sign_at
          ? window
            .moment(data.signatory_metadata?.sign_at)
            .format(' HH:mm:ss DD/MM/YYYY')
          : '')
      )
    },
    handleGetImageBase64 (data) {
      if (!data?.signatory_metadata?.signature?.signature_base64) return ''

      const prefix = 'data:image/png;base64,'
      const content = data?.signatory_metadata?.signature?.signature_base64
      return prefix + content
    },
    handlePrint () {
      this.$refs[this.elementID].print(
        400,
        this.getEnvelopeNameByType(this.documentType),
        true
      )
      // window.print()
    },
    handleDownload () {
      this.$refs[this.elementID].download(
        this.getEnvelopeNameByType(this.documentType)
      )
    },
    async handleProcessDocument () {
      try {
        let base64String
        if (this.isDocBase64) {
          base64String = this.removePrefix(this.urlPDF)
        } else {
          base64String = await this.getFileAsBase64(this.urlPDF)
        }

        const file = appUtils.convertBase64tToFile(
          base64String,
          'PdfViewer.pdf'
        )

        const params = { file }
        const formData = new FormData()
        for (const key in params) {
          formData.append(key, params[key])
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getSignaturesDetail(formData)
        const responseData = response || []
        const formatResponse = responseData.map((item) => {
          this.removeParentheses(item)

          return {
            ...item,
            signature_index: this.getNumberFromSignature(item?.index_name),
            signed_at: this.convertDateFormat(item?.signed_at)
          }
        })
        this.signatureData = formatResponse

        return formatResponse

        // this.signed_at = response.data?.timestamp
        //   ? window
        //     .moment(response.data?.timestamp)
        //     .format('HH:mm:ss DD/MM/YYYY')
        //   : ''
      } catch (error) {
        console.log(error)
      }
    },
    generatePDFSrcBase64 (content) {
      const prefix = 'data:application/pdf;base64,'

      return prefix + content
    },
    removePrefix (base64String) {
      let prefix = 'data:image/png;base64,'
      if (base64String.startsWith(prefix)) {
        return base64String.slice(prefix.length)
      }
      return base64String
    },
    getAvatar (signatory) {
      if (!signatory?.user?.avatar) return avatar

      return appUtils.getImageURL(signatory?.user?.avatar)
    },
    handleCheckSignatories () {
      this.signatories.forEach((signatory) => {
        const [signLLx, signLLy] =
          signatory?.signatory_metadata?.signature_position_rectangle
            ?.split(',')
            ?.map((item) => parseInt(item))

        const signX = signLLx + this.imageX / 2
        const signY = signLLy + this.imageY / 2

        const imageDefault = document.getElementById(
          `${this.imageID}_${signatory?.id}_default`
        )

        if (imageDefault) {
          imageDefault.style.left = signX + 'px'
          imageDefault.style.top = signY + 'px'
        }
      })
    },
    async getFileAsBase64 (url) {
      try {
        // Fetch the file from the URL
        const response = await fetch(url)

        // Ensure the response is ok
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        // Get the Blob from the response
        const blob = await response.blob()

        // Convert the Blob to a Base64 string
        const base64String = await this.blobToBase64(blob)
        // const base64 = this.removePrefixDocument(base64String)

        return base64String
      } catch (error) {
        console.error('Error fetching or converting the file:', error)
      }
    },

    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    },
    removePrefixDocument (base64) {
      if (!base64) return

      let prefix = 'data:application/pdf;base64,'
      if (base64.startsWith(prefix)) {
        return base64.slice(prefix.length)
      }
      return base64
    },
    getNumberFromSignature (signature) {
      if (!signature) return

      const prefix = 'Signature'
      if (signature?.startsWith(prefix)) {
        return Number(signature.slice(prefix.length))
      }
      return null
    },
    removeParentheses (obj) {
      if (!obj) return

      for (const key in obj) {
        if (typeof obj[key] === 'string') {
          obj[key] = obj[key].replace('(', '').replace(')', '')
        }
      }
    },

    convertDateFormat (dateStr) {
      if (!dateStr) return ''

      // Remove the "D:" at the start and "Z" at the end
      const trimmedDateStr = dateStr.slice(2, -1)

      // Parse the date
      const date = window.moment.utc(trimmedDateStr, 'YYYYMMDDHHmmss')
      date.add(7, 'hours')

      // Format the date
      const formattedDate = date.format('HH:mm:ss - DD/MM/YYYY')

      return formattedDate
    }
  }
}
</script>
      
  <style lang="scss" scoped>
.vue-pdf-embed {
  margin: 0 auto;
}

.vue-pdf-embed__page {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.cs-container {
  overflow: auto;
  // pointer-events: none;
}

.pdf-container {
  width: 1000px;
  border: 1px solid #000;
  margin: 0 auto;
  position: relative;
}

::v-deep {
  .vue-pdf-embed {
    .vue-pdf-embed__page {
      box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0 !important;
  }
}

#image {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.cs-sign {
  position: sticky;
  top: 0px;
  right: 20px;
  background-color: rgb(74, 74, 74);
  z-index: 100000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  padding: 8px 16px;
}

.popup {
  position: absolute;
  background-color: white;
  z-index: 20000;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.cs-default-signature {
  position: absolute;
  z-index: 2000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border: 1px dashed #696969;
  padding: 4px;
  width: 80px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc820;
  cursor: pointer !important;
}
</style>